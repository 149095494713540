<template>
  <div>
    <title>SIM INVENTORY ~ EDIT ORDER LIST DATA PROCESS DETAILS</title>
    <section class="content-header">
      <h1>
        Edit Order List Data Process Details
        <br />
        <h4>Please update the transaction data for the detailed order process</h4>
      </h1>
      <ol class="breadcrumb">
        <li>
          <a href="#"><i class="fa fa-dashboard"></i> Home</a>
        </li>
        <li class="active">Transaction List Order Process Detail</li>
      </ol>
    </section>
    <section class="content">
      <div v-if="loading" class="load">
        <img width="130" src="@/assets/loader.gif" alt="" />
      </div>
      
      <div class="row">
        <!-- left column -->
        <div class="col-md-12">
          <!-- general form elements -->
          <div class="box box-primary">
            <div class="box-header with-border">
              <h3 class="box-title">Order List Data Process Details</h3>
            </div>
            <!-- /.box-header -->
            <!-- form start -->
            <form role="form">
              <div class="box-body">
                <div class="form-group">
                  <label for="exampleInputEmail1">Process Code </label>
                  <input
                    type="text"
                    readonly
                    v-model="kodeorderprosesd"
                    autocomplete="off"
                    class="form-control"
                  />
                  <input
                    type="hidden"
                    readonly
                    v-model="idorderprosesd"
                    autocomplete="off"
                    class="form-control"
                  />
                  <input
                    type="hidden"
                    readonly
                    v-model="iddaftarorderprosesd"
                    autocomplete="off"
                    class="form-control"
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Start Date </label>
                  <input
                    type="date"
                    v-model="tglmulaiorderprosesd"
                    autocomplete="off"
                    class="form-control"
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">End Date</label>
                  <input
                    type="date"
                    v-model="tglselesaiorderprosesd"
                    autocomplete="off"
                    class="form-control"
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Notes </label>
                  <input
                    type="text"
                    v-model="notesorderprosesd"
                    autocomplete="off"
                    class="form-control"
                  />
                </div>
              </div>

              <div class="box-footer">
                <button
                  @click="saveData()"
                  type="button"
                  class="btn btn-primary"
                > <i class="fa fa-save"></i>
                  Save
                </button>
                &nbsp;
                <router-link to="/daftar-order">
                  <button class="btn btn-success"><i class="fa fa-angle-double-left"></i> Back</button>
                </router-link>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import swal from "sweetalert";
var axios = require("axios");
export default {
  name: "addmasterdataorderprosed",
  data() {
    return {
      loading: false,
      idorderprosesd: "",
      iddaftarorderprosesd: "",
      kodeorderprosesd: "",
      notesorderprosesd: "",
      tglmulaiorderprosesd: "",
      tglselesaiorderprosesd: ""
    };
  },
  created() {
    this.fetchdata();
  },
  methods: {
    async fetchdata() {
      this.loading = true;
      // const urlAPIget = "http://26.183.23.191/inventory/backend/inventory/api/daftar_order_d_proses/getdaftar_order_d_prosesbyid?id=" + this.$route.params.id;
      const urlAPIget =
        this.$apiurl +
        "daftar_order_d_proses/getdaftar_order_d_prosesbyid?id=" +
        this.$route.params.id;
      const tokenlogin = sessionStorage.getItem("token");
      const headers = {
        Accept: "application/json",
        Authorization: tokenlogin
      };
      axios
        .get(urlAPIget, { headers })
        .then((resp) => {
          this.idorderprosesd = resp.data.data.id;
          this.iddaftarorderprosesd = resp.data.data.id_daftar_order_d;
          this.kodeorderprosesd = resp.data.data.kode_proses;
          this.notesorderprosesd = resp.data.data.notes;
          this.tglmulaiorderprosesd = resp.data.data.tanggal_mulai;
          this.tglselesaiorderprosesd = resp.data.data.tanggal_selesai;
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },
    async updateData() {
      this.loading = true;
      var kodeproses = this.kodeorderprosesd;
      var notesorderd = this.notesorderprosesd;
      var tglmulaiorderd = this.notesorderprosesd;
      var tglselesaiorderd = this.tglselesaiorderprosesd;
      var idx = this.idorderprosesd;
      // var idxdaftarorderproses = this.iddaftarorderprosesd
      if (kodeproses == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Process Code can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else if (notesorderd == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Notes can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else if (tglmulaiorderd == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Start Date can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else if (tglselesaiorderd == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "End Date can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else {
        const kodeuser = sessionStorage.getItem("kodeuser");
        const paramdatadetail = {
          id_daftar_order_d: this.iddaftarorderprosesd,
          kode_proses: this.kodeorderprosesd,
          notes: this.notesorderprosesd,
          tanggal_mulai: this.notesorderprosesd,
          tanggal_selesai: this.tglselesaiorderprosesd,
          kode_user: kodeuser
        };
        // const tokenlogin = localStorage.getItem("token");
        const tokenlogin = sessionStorage.getItem("token");
        const headers = {
          Accept: "application/json",
          Authorization: tokenlogin
        };
        // const urlAPIUpdateDatadetail = "http://26.183.23.191/inventory/backend/inventory/api/daftar_order_d_proses/updatedaftar_order_d_proses/" + idx;
        const urlAPIUpdateDatadetail =
          this.$apiurl +
          "daftar_order_d_proses/updatedaftar_order_d_proses/" +
          idx;
        axios
          .put(urlAPIUpdateDatadetail, paramdatadetail, { headers: headers })
          .then((respn) => {
            if (respn.data.status == true) {
              swal({
                icon: "success",
                title: "Success",
                text: "Update data successfully",
                showConfirmButton: false
              });
              this.loading = false;
            } else {
              swal({
                icon: "warning",
                title: "Warning",
                text: "Update data failed",
                showConfirmButton: false
              });
              this.loading = false;
            }
          })
          .catch((err) => {
            swal({
              icon: "warning",
              title: "Warning",
              text: err.response.data,
              showConfirmButton: false
            });
            console.log(err);
            this.loading = false;
          });
      }
    }
  }
};
</script>
<style>
.load {
  width: 20%;
  margin: 0 auto;
}
</style>
